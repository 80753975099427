body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-yellow {
  color: #f89d1c !important;
}
.text-red {
  color: #ee2a32 !important;
}
.text-blue {
  color: #4c76bb !important;
}

.border-yellow {
  border-color: #f89d1c !important;
}
.border-red {
  border-color: #ee2a32 !important;
}
.border-blue {
  border-color: #4c76bb !important;
}

.bg-blue {
  background-color: #4c76bb;
}
.bg-yellow-400 {
  background-color: #fbbf24 !important;
}

.bg-yellow-500 {
  background-color: #f59e0b !important;
}
.bg-yellow-600 {
  background-color: #d97706 !important;
}
.bg-green-400 {
  background-color: #34d399 !important;
}

.bg-green-500 {
  background-color: #10b981 !important;
}
.bg-green-600 {
  background-color: #059669 !important;
}

.bg-blue-400 {
  background-color: #60a5fa !important;
}

.bg-blue-500 {
  background-color: #3b82f6 !important;
}
.bg-blue-600 {
  background-color: #2563eb !important;
}

.bg-indigo-400 {
  background-color: #818cf8 !important;
}

.bg-indigo-500 {
  background-color: #6366f1 !important;
}
.bg-indigo-600 {
  background-color: #4f46e5 !important;
}

.bg-purple-400 {
  background-color: #a78bfa !important;
}

.bg-purple-500 {
  background-color: #8b5cf6 !important;
}
.bg-purple-600 {
  background-color: #7c3aed !important;
}

.bg-pink-400 {
  background-color: #f472b6 !important;
}

.bg-pink-500 {
  background-color: #ec4899 !important;
}
.bg-pink-600 {
  background-color: #db2777 !important;
}

.chart-bg-yellow {
  background-color: #fdf9f5 !important;
}

.chart-section {
  // background-color: #fef4e9;
  // background-color: #f6f7fd;

  .chart-title {
    margin-bottom: 32px;
    border-bottom: 2px solid;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    span {
      font-size: 28px;
      font-weight: 600;
      // color: #f89d1c;
    }
  }
  .chart-section-bg {
  }
  .chart-description {
    //text-align: center;
    margin-left: 48px;
  }
}

/* Sections Header
--------------------------------*/
.section-header {
  margin-bottom: 60px;
  position: relative;
  padding-bottom: 20px;
}
.section-header::before {
  content: "";
  position: absolute;
  display: block;
  width: 60px;
  height: 5px;
  background: #f82249;
  bottom: 0;
  left: calc(50% - 25px);
}
.section-header h2 {
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 10px;
}
.section-header p {
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: #9195a2;
}
.section-with-bg {
  background-color: #f6f7fd;
  // background-color: #f6f7fd;
}

/*--------------------------------------------------------------
# Schedule Section
--------------------------------------------------------------*/

#schedule {
  padding: 60px 0 60px 0;
}
#schedule .nav-tabs {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: calc(75% + 24px);
  border-bottom: 0;
  //margin-bottom: 30px;
  // display: flex;
  // flex-direction: row;
}
// #schedule .nav-tabs li {
//   display: flex;
//   justify-content: space-between;
// }
#schedule .nav-tabs li {
  width: 100%;
  max-width: 240px;
  margin-bottom: 30px;
  // margin: 0 10px;
  // display: inline-block;
  // margin-bottom: 0;
  //width: 100%;
}
#schedule .nav-tabs a {
  border: none;
  border-radius: 50px;
  font-weight: 600;
  background-color: #8a8a8a;
  color: #fff;
  //padding: 10px 100px;
}

.question-tab {
  font-size: 16px;
  .q-row {
    padding: 8px 0px;
    border-bottom: 1px solid #cad4f6;
  }
  a {
    color: #000;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 480px) {
    min-height: 650px;
  }
}
@media (max-width: 1400px) {
  #schedule .nav-tabs {
    max-width: 100%;
    //justify-content: space-around;
  }
  // #schedule .nav-tabs li {
  //   width: 100%;
  //   max-width: 280px;
  // }
}
@media (max-width: 991px) {
  #schedule .nav-tabs {
    max-width: 100%;
  }
  #schedule .nav-tabs a {
    padding: 8px 60px;
  }
}
@media (max-width: 767px) {
  #schedule .nav-tabs a {
    padding: 8px 50px;
  }
}
@media (max-width: 480px) {
  #schedule .nav-tabs li {
    max-width: 100%;
  }
  #schedule .nav-tabs a {
    padding: 8px 30px;
  }
}
#schedule .nav-tabs a.active {
  background-color: #f82249;
  color: #fff;
}
#schedule .sub-heading {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto 30px auto;
}
@media (min-width: 991px) {
  #schedule .sub-heading {
    width: 75%;
  }
}
#schedule .tab-pane {
  transition: ease-in-out 0.2s;
}
#schedule .schedule-item {
  border-bottom: 1px solid #cad4f6;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
}
#schedule .schedule-item:hover {
  background-color: #fff;
}
// #schedule .schedule-item time {
//   margin-bottom: 15px;
//   display: inline-block;
// }

.schedule-item {
  display: flex;
  align-items: center;
  // time {
  //   font-size: 14px;
  // }
  h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  span {
    //font-style: italic;
    //color: #19328e;
    font-weight: normal;
    font-size: 16px;
  }
  p {
    font-style: italic;
    color: #152b79;
    margin-bottom: 0;
  }
}

.schedule-title {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
}
// #schedule .schedule-item time {
//   padding-bottom: 5px;
//   display: inline-block;
// }
// #schedule .schedule-item .speaker {
//   width: 60px;
//   height: 60px;
//   overflow: hidden;
//   border-radius: 50%;
//   float: left;
//   margin: 0 10px 10px 0;
// }
// #schedule .schedule-item .speaker img {
//   height: 100%;
//   transform: translateX(-50%);
//   margin-left: 50%;
//   transition: all ease-in-out 0.3s;
// }
// #schedule .schedule-item h4 {
//   font-size: 18px;
//   font-weight: 600;
//   margin-bottom: 5px;
// }
// #schedule .schedule-item h4 span {
//   font-style: italic;
//   color: #19328e;
//   font-weight: normal;
//   font-size: 16px;
// }
// #schedule .schedule-item p {
//   font-style: italic;
//   color: #152b79;
//   margin-bottom: 0;
// }

.event-speaker {
  display: flex;

  .avatar {
    width: 100%;
    max-width: 42px !important;
    height: 42px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .description {
    margin-left: 12px;
    h4 {
      a {
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.schedule-tabs {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin: 0 10px 10px 0;
}

.vid-container {
  // :first-child {
  //   height: 100% !important;
  // }
  .col-video {
    width: 100%;
    height: 172px;
    background-color: #000000;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 120px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(1, 4, 136, 0.9);
}
#header.header-transparent {
  background: transparent;
}
#header.header-scrolled {
  background: rgba(2, 52, 121, 0.9);
  height: 120px;
}
#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}
#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}
#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 80px;
}

#header-default {
  height: 120px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(1, 4, 136, 0.9);
}
#header-default.header-transparent {
  //background: transparent;
}
#header-default.header-scrolled {
  background: rgba(2, 52, 121, 0.9);
  height: 120px;
}
#header-default .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}
#header-default .logo h1 a,
#header-default .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}
#header-default .logo img {
  padding: 0;
  margin: 0;
  max-height: 120px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 16px;
  font-weight: 700;
  font-family: "Nunito", sans-serif;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 3px;
  left: 30px;
  background-color: #ffcb05;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}
.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 25px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #01036f;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #ffcb05;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 3, 91, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile > ul > li > a:before {
  left: 20px;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #0205a1;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #3f43fd;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #ffcb05;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

#hero {
  width: 100%;
  // height: 100%;
  // min-height: 90vh;
  // background: url("./assets/img/hero-bg1.jpg");
  // background: url("./assets/img/footer_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  padding: 120px 0 0 0;
  .hero-title-container {
    padding: 100px 0;
    img {
      // height: 460px;
    }
  }
  .img-title {
    // margin-top: -400px;
    // height: 250px;
    width: 48vw;

    margin-top: 12vh;
  }
  @media screen and (max-width: 480px) {
    background-size: cover;
    background-position: center;
    #hero .hero-img {
      height: 600px;
      padding-top: 100px;
    }

    .img-title {
      // margin-top: -400px;
      width: 90vw;
      margin: 120px 0px;
    }
  }
}

#hero:before {
  content: "";
  /*background: rgba(10, 11, 100, 0.91);*/
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero h1 {
  margin: 0 0 20px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: rgba(255, 255, 255, 0.8);
}
#hero h1 span {
  color: #fff;
  border-bottom: 4px solid #ffcb05;
}
#hero h2 {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 40px;
  font-size: 24px;
}
#hero .btn-get-started {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background: #ffcb05;
}
#hero .btn-get-started:hover {
  background: #17b57d;
}
#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

#hero .hero-img {
  //height: 600px;
  padding-top: 100px;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  #hero {
    padding-top: 80px;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    max-width: 50%;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
.hero-waves {
  display: block;
  margin-top: 60px;
  width: 100%;
  height: 60px;
  z-index: 5;
  position: relative;
}

.wave1 use {
  -webkit-animation: move-forever1 10s linear infinite;
  animation: move-forever1 10s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave2 use {
  -webkit-animation: move-forever2 8s linear infinite;
  animation: move-forever2 8s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

.wave3 use {
  -webkit-animation: move-forever3 6s linear infinite;
  animation: move-forever3 6s linear infinite;
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
}

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
.team {
  background: #fff;
}
.team .member {
  text-align: center;
  margin-bottom: 80px;
  position: relative;
}
.team .member .pic {
  border-radius: 4px;
  overflow: hidden;
}
.team .member img {
  transition: all ease-in-out 0.4s;
}
.team .member:hover img {
  transform: scale(1.1);
}
.team .member .member-info {
  position: absolute;
  bottom: -80px;
  left: 0px;
  right: 0px;
  background: rgba(255, 255, 255, 0.9);
  padding: 15px 0;
  border-radius: 0 0 4px 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.team .member h4 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 16px;
  color: #01036f;
  position: relative;
  padding-bottom: 10px;
}
.team .member h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #0d12fc;
  bottom: 0;
  left: calc(50% - 25px);
}
.team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
  color: #01036f;
}
.team .member .social {
  margin-top: 10px;
}
.team .member .social a {
  transition: color 0.3s;
  color: #01036f;
}
.team .member .social a:hover {
  color: #ffcb05;
}
.team .member .social i {
  font-size: 16px;
  margin: 0 2px;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f5f5ff;
}

.section-title {
  padding-bottom: 40px;
}
.section-title h2 {
  font-size: 25px;
  font-weight: 700;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #010483;
  font-family: "Nunito", sans-serif;
}
.section-title h2::after {
  content: "";
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #ffcb05;
  margin: 4px 10px;
}
.section-title p {
  margin: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Nunito", sans-serif;
  color: #aaaaaa;
}

.gallery {
  .gallery-item {
    //overflow: hidden !important;
    background-size: cover;
    height: 200px;
    width: 100%;
    padding: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: #fff;

    .alt-text {
      // transition: all linear 0.5s;
      // visibility: hidden;
      display: none;
    }
    &:hover {
      transition: all linear 0.3s;
      box-shadow: -2px -89px 80px -18px rgba(0, 0, 0, 0.38) inset;
      -webkit-box-shadow: -2px -89px 80px -18px rgba(0, 0, 0, 0.38) inset;
      -moz-box-shadow: -2px -89px 80px -18px rgba(0, 0, 0, 0.38) inset;

      .alt-text {
        display: block;
        // transition: all linear 0.9s;
      }
    }
  }
}
// .gallery .gallery-item
//  {
//   transition: all ease-in-out 0.4s;
// }
// .gallery .gallery-item:hover  {
//   transform: scale(1.1);
// }

#footer {
  /*background: #010351;*/
  //background: url("./assets/img/footer_bg.jpg");
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .footer-top {
  padding: 60px 0 0 0;
}
#footer .footer-top .footer-info {
  /*margin-bottom: 15px;
  border-top: 4px solid #ffcb05;
  background: #010246;*/
  h3 {
    color: #fff;
  }
  color: #fff;
  text-align: center;
  padding: 30px 20px;
  padding-bottom: 0;
}
#footer .footer-top .footer-info h3 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-info p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Nunito", sans-serif;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #ffcb05;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #61ebba;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  color: #4be8b0;
}

#footer .footer-top .footer-newsletter {
  img {
    @media screen and (max-width: 500px) {
      height: auto;
      width: 100%;
    }
  }
}

#footer .footer-top .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 6px 10px 6px 15px;
  position: relative;
  border-radius: 50px;
}
#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px;
  width: calc(100% - 110px);
}
#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -2px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #ffcb05;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
}
#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #149f6e;
}
#footer .copyright {
  border-top: 1px solid #0b306a;
  text-align: center;
  padding-top: 30px;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #fff;
}
@media (max-width: 575px) {
  #footer .footer-top .footer-info {
    margin: -20px 0 30px 0;
  }
}

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #ffcb05;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #2be4a2;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.history-slider {
  .slick-center {
    //margin: 50px;
    .slick-card {
      background-color: #2196f3;
      color: #fff;
      height: 400px;
      transition: height 1s;
      //overflow-y: scroll;
      //height: 300px;
      // .date {
      //   background-color: #f3a021;
      //   color: #fff;
      // }
    }
  }
}

.image-slider {
  // display: flex;
  // align-items: center;
  // /* the slides */
  // .slick-slide {
  //   margin: 0 5px;
  // }
  // /* the parent */
  // .slick-list {
  //   margin: 0 -5px;
  // }
  .img-card {
    //height: 200px;
    //background-color: #059669;
    padding: 24px 5px;
    //margin: 0px -5px;

    //margin: 0px 20px;

    img {
      height: 200px;
      width: 100%;
    }
  }

  .slick-center {
    .img-card {
      border: 5px solid #f3a021;
      //transition: all ease-in 0.1s;
      //background-color: #010483;
      padding: 4px;

      img {
        height: 236px;
        width: 100%;
      }
    }
  }

  .slick-dots.slick-thumb {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .slick-dots.slick-thumb li {
    //margin-top: 20px;
    width: 10px;
    height: 10px;
    margin: 0 6px;
    border-radius: 999px;
    transition: all 0.5s ease-in-out;
    will-change: width;
    background-color: rgb(187, 187, 187);
  }
  .slick-dots.slick-thumb li.slick-active {
    background-color: #f3a021;
    width: 24px;
  }
  .dot {
    //top: 20;
    //margin-top: -5px;
    width: 10px;
    height: 10px;
    cursor: pointer;
  }
}

.slick-card-container {
  position: relative;
  .line {
    border-top: 2px solid #f3a021;
    position: absolute;
    z-index: 100;
    top: 28px !important;
    left: 0;
    right: 0;
    //margin-top: -16px;
  }
}

.slick-card {
  margin-top: 64px;
  .title {
    // font-size: 20px;
    // font-weight: 700px;
  }
  .date {
    position: absolute;
    z-index: 110;
    top: 0;
    left: -32px;
    right: 0;
    margin: 0 auto;
    padding: 8px 16px;

    border-radius: 16px;
    // background-color: #b8b8b8;
    // color: #fff;
    background-color: #f3a021;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    max-width: 160px;
    display: flex;
    justify-content: center;
  }
  border-radius: 16px;
  padding: 16px;
  padding-top: 32px;
  background-color: #fff;

  max-width: 320px;
  height: 300px;
  overflow-y: hidden;

  //height: 300px;
}

.history-slider-2 {
  .slick-center {
    .slick-card-2 {
      transition: all linear 0.3s;
      .title {
        color: #fcbd10;
        font-weight: 600;
      }
      .date {
        color: #010483;
        font-weight: 600;
        font-size: 18px;
      }
      .timeline {
        .line {
          background-color: #fcbd10;
        }
        .icon {
          color: #fcbd10;
          font-size: 22px;
        }
      }
    }
  }
  .slick-card-2 {
    //background-color: #000000;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1rem;
      text-align: justify;
      padding: 0px 16px;
    }

    img {
      margin: 8px 0px;
    }

    .date {
      height: 30px;
    }
    .timeline {
      width: 100%;
      position: relative;
      height: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .line {
        width: 100%;
        height: 1px;
        background-color: #aaaaaa;
      }
      .icon {
        background-color: #fff;
        color: #aaaaaa;
        font-size: 18px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    .description {
      padding: 0px 16px;
      text-align: justify;
    }
  }
}

.info-graphic-page {
  margin-top: 140px;
  width: 100%;

  .iframe-container {
    overflow: hidden;
    margin: auto;
    width: 1200px;
    iframe {
      margin-top: -120px;
      margin-bottom: -690px;
      width: 1200px;
      height: 3000px;
    }
  }
}

// Салбарын мэдээ
.salbar-statistik {
  .table-container {
    font-size: 14px;
    background-color: #ffffff;
    padding: 8px;
    padding-bottom: 24px;
    border-radius: 8px;
    //border: 1px solid #ddd;

    .link-btn {
      text-decoration: none;
      color: #eb7e26;
      //border: 1px solid #eb7e26;
      background-color: #eb7e2620;
      border-radius: 4px;
      padding: 4px 8px;
      &:hover {
        transition: all 0.3s ease-in-out;
        background-color: #eb7e26;
        color: #fff;
      }
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      overflow: scroll;
    }
  }
  table {
    width: 100%;
    //padding: 8px;
    thead {
      th {
        padding: 4px 8px;
        border-bottom: 1px solid #ddd;
        &:first-child {
          border-top-left-radius: 8px;
        }
      }
      &:first-child {
        border-top-left-radius: 8px;
      }
    }
    tbody {
      tr {
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        border-bottom: 1px solid #ddd;
        padding: 8px 0;
        &:hover {
          background-color: rgb(224, 224, 224);
        }
        td {
          padding: 12px 8px !important;
          margin: 8px 0px !important;
        }
      }
    }
  }
}

.features {
  padding-top: 36px;
}

.att-chart-container {
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.att-table-container {
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 0px;
  table {
    width: 100%;
    thead {
      tr {
        th {
          // &:first-child {
          //   text-align: right;
          // }
          color: #4a5360;
          padding: 4px 8px;
          //background-color: #059669;
          //border-right: 1px solid #d6d6d6;
          text-align: center;
          border-bottom: 1px solid #d6d6d6;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #d6d6d6;
        td {
          padding: 8px;
        }
        &:hover {
          background-color: #d6d6d620;
        }
      }
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    overflow: scroll;
  }
}
