.container {
  width: 210mm;
  margin: auto;
  margin-top: 32px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 50vh;
  page-break-inside: avoid;
  .card {
    border: 1px dashed #000;
    width: 90mm;
    height: 130mm;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: content-box;
    background-image: url("../../assets/images/mandat_back.jpg");
    display: flex;
    page-break-inside: avoid;
    flex-direction: column;
    .logoCon {
      display: flex;
      justify-content: center;
      .yamLogo {
        height: 90px;
        margin-top: 40px;
      }
      .jilLogo {
        height: 170px;
      }
    }
    .title {
      text-align: center;
      display: flex;
      flex-direction: column;
      font-family: Arial, Helvetica, sans-serif;
      margin-top: 14px;

      .subTitle {
        color: rgba(23, 84, 173, 255);
        font-weight: 600;
        font-size: 12px;
        // color: white;
      }
      .titleMain {
        font-size: 24px;
        color: rgba(23, 84, 173, 255);
        font-weight: 700;
      }
    }
    .qrCodeCon {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
    .textCon {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      line-height: 24px;
      font-size: 24px;
      font-family: Arial, Helvetica, sans-serif;
      margin-top: 8px;

      span {
        color: rgba(23, 84, 173, 255);
        &:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }
}
@media print {
  @page {
    size: A4;
    margin-top: 30px;
    margin: auto;
    margin-left: 55px;
  }

  table {
    tbody {
      tr {
        page-break-inside: avoid;
        td {
          page-break-inside: avoid;
          .card {
            page-break-inside: avoid;
          }
        }
      }
    }
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card {
    border: 1px dashed #000;
    width: 90mm;
    height: 130mm;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: content-box;
    background-image: url("../../assets/images/mandat_back.jpg");
    display: flex;
    page-break-inside: avoid;
    flex-direction: column;
    .logoCon {
      display: flex;
      justify-content: center;
      .yamLogo {
        height: 90px;
        margin-top: 40px;
      }
      .jilLogo {
        height: 170px;
      }
    }
    .title {
      text-align: center;
      display: flex;
      flex-direction: column;
      font-family: Arial, Helvetica, sans-serif;
      margin-top: 14px;

      .subTitle {
        color: rgba(23, 84, 173, 255);
        font-weight: 600;
        font-size: 12px;
        // color: white;
      }
      .titleMain {
        font-size: 24px;
        color: rgba(23, 84, 173, 255);
        font-weight: 700;
      }
    }
    .qrCodeCon {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
    .textCon {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      line-height: 24px;
      font-size: 24px;
      font-family: Arial, Helvetica, sans-serif;
      margin-top: 8px;

      span {
        color: rgba(23, 84, 173, 255);

        &:nth-child(2) {
          font-size: 18px;
        }
      }
    }
  }
}
