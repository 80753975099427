.gallerySlick {
  //background-color: aqua;
  .slick-center {
    //margin: 50px;
    .imgCard {
      .img {
        height: 240px;
        transition: height 1s;
      }
    }
  }
}

.imgCard {
  //   width: 300px;
  //   height: 200px;
  //   background-size: cvoer;
  //background-color: rgb(44, 15, 4);
  img {
    width: 100%;
    height: 200px;
  }
}
