.container {
  width: 210mm;
  margin: auto;
  margin-top: 32px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  min-height: 50vh;
  page-break-inside: avoid;
  .card {
    width: 90mm;
    // border: 1px dashed #000;
    height: 130mm;
    display: flex;
    page-break-inside: avoid;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .backTitleCon {
      display: flex;
      margin-right: 16px;
      p {
        color: rgba(23, 84, 173, 255);
        font-weight: 700;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 16px;
      }
    }
    .cardTitle {
      display: flex;
      margin-right: 50px;
      p {
        color: rgba(23, 84, 173, 255);
        font-weight: 700;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 16px;
        img {
          width: 90px;
        }
        span {
          font-size: 12px;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}
@media print {
  @page {
    size: A4;
    margin-top: 30px;
    width: 100%;
    margin: auto;
    margin-left: 55px;
  }

  table {
    tbody {
      tr {
        page-break-inside: avoid;
        td {
          page-break-inside: avoid;
          .card {
            page-break-inside: avoid;
          }
        }
      }
    }
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card {
    width: 90mm;
    height: 130mm;
    // border: 1px dashed #000;
    display: flex;
    page-break-inside: avoid;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .backTitleCon {
      display: flex;
      p {
        font-weight: 700;
        color: rgba(23, 84, 173, 255);
      }
      div {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-top: 30px;
      }
    }
    .cardTitle {
      display: flex;
      margin-right: 50px;
      p {
        color: rgba(23, 84, 173, 255);
        font-weight: 700;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: 16px;
        img {
          width: 90px;
        }
        span {
          font-size: 12px;
          font-family: Arial, Helvetica, sans-serif;
        }
      }
    }
  }
}
